import { ETHERSCAN_PREFIXES } from 'helpers/constants'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'

export function getEtherscanLink(chainId, data, type) {
  let prefix = `https://${
    ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]
  }etherscan.io`

  if (chainId === 80001) {
    prefix = `https://mumbai.polygonscan.com`
  }

  if (chainId === 137) {
    prefix = `https://polygonscan.com`
  }

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export const getCurrentChainId = async (provider) => {
  const web3 = new Web3(provider)
  const currentChainId = await web3.eth.getChainId()
  return currentChainId
}

export const weiToEth = (balance, decimals = 18) => {
  const displayBalance = balance
    .dividedBy(new BigNumber(10).pow(decimals))
    .toNumber()
  return Math.floor((displayBalance + Number.EPSILON) * 10000) / 10000
}

export const weiToEthNum = (balance, decimals = 18) => {
  const displayBalance = new BigNumber(balance).dividedBy(
    new BigNumber(10).pow(decimals)
  )
  return displayBalance.toNumber()
}

export const weiToEthString = (balance, decimals = 18) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toString()
}

export const weiToEthLongString = (balance, decimals = 18, showLength = 18) => {
  if (weiToEthNum(balance, decimals) >= 0.00001)
    return weiToEthNum(balance, decimals)
  else if (weiToEthNum(balance, decimals) === 0) return 0
  else {
    const zeroCounts = decimals - balance?.toString().length
    const displayBalance =
      '0.' +
      Array(zeroCounts).fill('0').join('') +
      balance?.toString().slice(0, showLength)
    return displayBalance?.replace(/(\.0+|0+)$/, '')
  }
}

export const weiToEthFormat = (balance, decimals = 18) => {
  return new Intl.NumberFormat().format(weiToEth(balance, decimals))
}

export const isValidAddress = (address) => {
  return Web3.utils.isAddress(address)
}

export const numberFormat = (number) => {
  return new Intl.NumberFormat().format(number)
}

export const calculateAPR = (xdefiDeposit, bonusMultiplier, totalUnits) => {
  /**
    Your shares   =   XDEFI you deposited   x   bonus multiplier
    Your % of shares   =   your shares   /   (your shares + amount of $XDEFI lock(lock-up period A)*(lock-up period A multiplier))
    Yearly rewards = your % of shares * $XDEFI yearly rewards
    APR = (yearly rewards + $XDEFI deposited)/($XDEFI deposited)
  */
  const XDEFI_REWARDS_PER_MONTH = 300000
  const yearlyRewards = XDEFI_REWARDS_PER_MONTH * 12
  const percentRewards = (xdefiDeposit * bonusMultiplier) / totalUnits

  const rewardPerPeriod = percentRewards * yearlyRewards
  const APR = (rewardPerPeriod / xdefiDeposit) * 100
  return isNaN(APR) ? 0 : APR.toFixed(0)
}

export const isUserRejected = (error) => {
  return (
    error?.message?.includes('User denied transaction signature') ||
    error?.message?.includes('user rejected') ||
    error?.code === 4001
  )
}

export const arrayToObject = (arrayData, key) =>
  arrayData.reduce((obj, item) => {
    obj[item?.[key]] = item
    return obj
  }, {})

export function precise(value, digit, fixedDigit) {
  if (!parseFloat(value) || !isFinite(parseFloat(value))) {
    return 0
  }
  const sign = Math.sign(parseFloat(value))
  if (parseFloat(Math.abs(value)) >= 1) {
    return fixedDigit
      ? parseFloat(Math.abs(value))
          .toFixed(digit)
          .match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)
          .input.replace(/(\.0+|0+)$/, '')
      : sign *
          parseFloat(Math.abs(value))
            .toFixed(digit)
            .match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)[1]
  } else if (parseFloat(Math.abs(value)) >= 0) {
    const zeroCount = -Math.floor(Math.log10(parseFloat(Math.abs(value))) + 1)
    if (zeroCount >= 17) return 0
    return fixedDigit
      ? parseFloat(Math.abs(value))
          .toFixed(zeroCount >= digit ? zeroCount + 1 : digit)
          .match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)
          .input.replace(/(\.0+|0+)$/, '')
      : sign *
          parseFloat(Math.abs(value))
            .toFixed(digit + zeroCount > 18 ? 18 : digit + zeroCount)
            .match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)[1]
  }
}

export const timeDifference = (difference) => {
  const daysDifference = Math.floor(difference / 60 / 60 / 24)
  difference -= daysDifference * 60 * 60 * 24

  const hoursDifference = Math.floor(difference / 60 / 60)
  difference -= hoursDifference * 60 * 60

  const minutesDifference = Math.floor(difference / 60)
  difference -= minutesDifference * 60

  const secondsDifference = Math.floor(difference)

  const remained = {
    day: daysDifference > 0 ? `${daysDifference}d ` : '',
    hour: hoursDifference > 0 ? `${hoursDifference}h ` : '',
    minute: minutesDifference > 0 ? `${minutesDifference}m ` : '',
    second: secondsDifference > 0 ? `${secondsDifference}sec` : '',
  }
  return `${remained?.day}${remained?.hour}${remained?.minute}${remained?.second}`
}

export const stringToBoolean = (string) => {
  if (!string) return false
  switch (string?.toLowerCase()?.trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true
    case 'false':
    case 'no':
    case '0':
    case null:
      return false
    default:
      return Boolean(string)
  }
}

export const getIndividualAPR = (withdrawable, locked, daysSinceLock) => {
  return ((withdrawable - locked) / locked) * (365 / daysSinceLock) * 100
}

export const getTotalAPR = (tokenPositionData = []) => {
  let totalAPRWithLocked = 0
  let totalLocked = 0

  tokenPositionData.forEach((item) => {
    const {
      withdrawable: withdrawableBig,
      depositedXDEFI: lockedBig,
      created,
    } = item
    const withdrawable = weiToEthNum(new BigNumber(withdrawableBig))
    const locked = weiToEthNum(new BigNumber(lockedBig))
    const daysSinceLock = (Date.now() / 1000 - Number(created)) / 86400
    const individualAPR = getIndividualAPR(withdrawable, locked, daysSinceLock)
    totalLocked += locked
    totalAPRWithLocked += individualAPR * locked
  })
  /* 
    So if one position has an APY of 10%, with 100 XEDEFI deposited, 
    and another position has an APY of 20%, with 75 deposited, then:
    (0.10*100 + 0.20*75)/(100+75)
  */
  return (totalAPRWithLocked / totalLocked) * 100
}
export const formatter = Intl.NumberFormat('en', { notation: 'compact' })

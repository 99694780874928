import React, { createContext, useEffect, useState, useCallback } from 'react'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import { useSelector, useDispatch } from 'react-redux'
import Onboard from 'bnc-onboard'

import { saveWallet } from 'store/user/actions'
import { clearTransactions } from 'store/transaction/actions'
import { weiToEthNum, stringToBoolean } from 'helpers/util'
import { getWithExpiry, setWithExpiry } from 'storage'

import {
  getERC20Token,
  getClaimContract,
  getXDEFIBarContract,
  getXDEFIBarHelperContract,
  TRIGGER_DATE,
} from 'helpers/constants'
import {
  CLAIM_CONTRACT_ADDRESS,
  XDEFI_DISTRIBUTION_ADDRESS,
  XDEFI_DISTRIBUTION_HELPER_ADDRESS,
  XDEFI_TOKEN_ADDRESS,
  DEFAULT_NETWORK_ID,
  BLOCKNATIVE_KEY,
  XDEFI_TOKEN,
} from 'helpers/config'
import { useInactiveListener } from 'hooks/useWeb3'

export const AccountContext = createContext({
  claimContract: null,
  xdefiContract: null,
  xdefiBarContract: null,
  xdefiBarHelperContract: null,
  infuraClaimContract: null,
  nftBaseURI: null,
  minimumUnits: null,
  account: null,
  chainId: null,
  provider: null,
  onboard: null,
  walletSelect: () => {},
  walletCheck: () => {},
  resetWallet: () => {},
  loadAccount: () => {},
  loadInfuraAccount: () => {},
})

const AccountProvider = ({ children }) => {
  const [claimContract, setClaimContract] = useState(null)
  const [infuraClaimContract, setInfuraClaimContract] = useState(null)
  const [xdefiContract, setXdefiContract] = useState(null)
  const [xdefiBarContract, setXdefiBarContract] = useState(null)
  const [xdefiBarHelperContract, setXdefiBarHelperContract] = useState(null)
  const [nftBaseURI, setNftBaseURI] = useState('')
  const [minimumUnits, setMinimumUnits] = useState(0)
  const [provider, setProvider] = useState(null)

  const wallet = useSelector(({ user }) => user.wallet)

  useInactiveListener()

  const [onboard, setOnBoard] = useState(null)
  const [account, setAccount] = useState('')
  const [chainId, setChainId] = useState(null)
  const connector = useSelector(({ user }) => user.wallet)

  const dispatch = useDispatch()

  const resetWallet = useCallback(() => {
    dispatch(saveWallet(''))
    setProvider(null)
    setAccount(null)
    return onboard?.walletReset()
  }, [dispatch, onboard])

  const trigger_date = getWithExpiry('trigger_date')
  useEffect(() => {
    if (!trigger_date || TRIGGER_DATE !== trigger_date) {
      dispatch(clearTransactions({ chainId: DEFAULT_NETWORK_ID })) // clearTransactions
      setWithExpiry('trigger_date', TRIGGER_DATE, 0)
    }
  }, [dispatch, trigger_date])

  useEffect(() => {
    // initialize onboard
    if (onboard) return
    try {
      const onboardInstance = Onboard({
        dappId: BLOCKNATIVE_KEY,
        networkId: DEFAULT_NETWORK_ID,
        darkMode: true,
        subscriptions: {
          address: setAccount,
          network: setChainId,
          wallet: (wallet) => {
            // instantiate web3 when the user has selected a wallet
            wallet?.name && console.log(`${wallet?.name} connected!`)
            if (wallet?.provider) {
              setProvider(wallet?.provider)
              dispatch(saveWallet(wallet?.name))
            }
          },
        },
        walletSelect: {
          wallets: [
            { walletName: 'xdefi' },
            {
              walletName: 'trezor',
              appUrl:
                process.env?.REACT_APP_APP_URL ||
                'https://vesting-ui.pages.dev',
              email: process.env?.REACT_APP_EMAIL || 'dp@xdefi.io',
              rpcUrl: process.env.REACT_APP_RPC_URL,
            },
            {
              walletName: 'ledger',
              rpcUrl: process.env.REACT_APP_RPC_URL,
            },
            {
              walletName: 'walletConnect',
              rpc: {
                [DEFAULT_NETWORK_ID]: process.env.REACT_APP_RPC_URL,
              },
            },
            {
              walletName: 'metamask',
              display: {
                mobile: false,
                desktop:
                  false || stringToBoolean(process.env.REACT_APP_SHOW_METAMASK),
              },
            },
          ],
        },
        walletCheck: [
          { checkName: 'derivationPath' },
          { checkName: 'connect' },
          { checkName: 'accounts' },
          { checkName: 'network' },
        ],
        hideBranding: true,
      })
      setOnBoard(onboardInstance)
    } catch (error) {
      if (error?.toString().includes('re-initialize')) {
        window.location.reload()
      }
    }
  }, [dispatch, onboard])

  useEffect(() => {
    async function walletCheck() {
      if (connector && onboard) {
        const walletSelected = await onboard.walletSelect(connector)
        if (!walletSelected) return false
        const ready = await onboard.walletCheck()
        return ready
      }
    }
    walletCheck()
  }, [connector, onboard])

  const loadAccount = useCallback(async () => {
    if (chainId !== DEFAULT_NETWORK_ID) return
    if (provider) {
      const claimContract = getClaimContract(provider, CLAIM_CONTRACT_ADDRESS)
      setClaimContract(claimContract)
      const xdefiBarContract = getXDEFIBarContract(
        provider,
        XDEFI_DISTRIBUTION_ADDRESS
      )
      setXdefiBarContract(xdefiBarContract)
      const xdefiBarHelperContract = getXDEFIBarHelperContract(
        provider,
        XDEFI_DISTRIBUTION_HELPER_ADDRESS
      )
      setXdefiBarHelperContract(xdefiBarHelperContract)
      const xdefiTokenContract = getERC20Token(provider, XDEFI_TOKEN_ADDRESS)
      setXdefiContract(xdefiTokenContract)
      const [nftBaseURI, MINIMUM_UNITS] = await Promise.all([
        xdefiBarContract?.methods?.baseURI()?.call(),
        xdefiBarContract?.methods?.MINIMUM_UNITS()?.call(),
      ])
      setNftBaseURI(nftBaseURI)
      setMinimumUnits(
        weiToEthNum(new BigNumber(MINIMUM_UNITS), XDEFI_TOKEN?.decimals)
      )
    }
  }, [chainId, provider])

  useEffect(() => {
    loadAccount()
  }, [loadAccount])

  const getAllowance = async (ERC20TokenAddress, userAddress) => {
    if (!provider || !ERC20TokenAddress || chainId !== DEFAULT_NETWORK_ID)
      return
    const ERC20Token = getERC20Token(provider, ERC20TokenAddress)
    if (!userAddress) return 0
    const allowance = await ERC20Token?.methods
      .allowance(userAddress, XDEFI_DISTRIBUTION_ADDRESS)
      .call()
    return allowance
  }

  const loadInfuraAccount = async () => {
    const provider = new Web3.providers.HttpProvider(
      process?.env?.REACT_APP_RPC_URL
    )
    const claimContract = getClaimContract(provider, CLAIM_CONTRACT_ADDRESS)
    setInfuraClaimContract(claimContract)
  }

  useEffect(() => {
    loadInfuraAccount()
  }, [])

  useEffect(() => {
    //XDEFI wallet did not fetch accounts data when run locally, add manually
    if (!provider || !account) return
    if (account && provider?.__XDEFI && provider?.accounts?.length === 0) {
      const newProvider = { ...provider, accounts: [account] }
      setProvider(newProvider)
    }
    if (
      (provider?.__XDEFI && provider?.accounts?.length > 0) ||
      !provider?.__XDEFI
    ) {
      loadAccount()
    }
  }, [provider, account, chainId, loadAccount])

  return (
    <AccountContext.Provider
      value={{
        claimContract,
        infuraClaimContract,
        xdefiContract,
        xdefiBarContract,
        xdefiBarHelperContract,
        nftBaseURI,
        minimumUnits,
        account,
        chainId,
        provider,
        onboard,
        walletSelect: onboard?.walletSelect,
        walletCheck: onboard?.walletCheck,
        walletReset: resetWallet,
        getAllowance,
        loadAccount,
        loadInfuraAccount,
        setProvider,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

export default AccountProvider

import { SAVE_WALLET, SAVE_BALANCE } from 'store/user/constants'

const initialState = {
  wallet: '',
  balances: {},
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_WALLET:
      return {
        ...state,
        wallet: action.payload,
      }
    case SAVE_BALANCE:
      if (action.payload?.chainId) {
        return {
          ...state,
          balances: {
            ...state.balances,
            [action.payload.chainId]: {
              ...(state.balances?.[action.payload.chainId] ?? {}),
              [action.payload.address]: action.payload.amount,
            },
          },
        }
      }
      return state
    default:
      return state
  }
}

export default userReducer

import Web3 from 'web3'

import ClaimAbi from 'helpers/abi/ClaimAbi.json'
import ERC20Json from 'helpers/abi/ERC20.json'
import XDEFIBarAbi from 'helpers/abi/XDEFIBarAbi.json'
import XDEFIBarHelperAbi from 'helpers/abi/XDEFIHelperAbi.json'

const ERC20Abi = ERC20Json.abi

export const ETHERSCAN_PREFIXES = {
  1: '',
  3: 'ropsten.',
  4: 'rinkeby.',
  5: 'goerli.',
  42: 'kovan.',
}

export const ACTIONS = {
  CLAIM: 'CLAIM',
  STAKE: 'STAKE',
  UNSTAKE: 'UNSTAKE',
  APPROVE: 'APPROVE',
}

export const getMessageFromType = (type) => {
  switch (type) {
    case ACTIONS.APPROVE: {
      return `Approve 'XDEFI' Success`
    }
    case ACTIONS.STAKE: {
      return 'Successfully staked!'
    }
    case ACTIONS.UNSTAKE: {
      return 'Withdrawal succesful'
    }
    case ACTIONS.CLAIM: {
      return 'Successfully claimed!'
    }
    default: {
      return ''
    }
  }
}

export const TRANSACTION_STATUS = {
  FAIL: 'FAIL',
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  REQUESTED: 'REQUESTED',
  REJECTED: 'REJECTED',
}

export const getClaimContract = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(ClaimAbi, address)
  return contract
}

export const getERC20Token = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(ERC20Abi, address)
  return contract
}

export const getXDEFIBarContract = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(XDEFIBarAbi, address)
  return contract
}

export const getXDEFIBarHelperContract = (provider, address) => {
  const web3 = new Web3(provider)
  const contract = new web3.eth.Contract(XDEFIBarHelperAbi, address)
  return contract
}

export const getBalance = async (provider, currency, userAddress) => {
  if (!currency?.address && currency?.symbol) {
    const web3 = new Web3(provider)
    const balance = await web3.eth.getBalance(userAddress)
    return balance
  } else if (currency?.address) {
    const tokenContract = getERC20Token(provider, currency?.address)
    try {
      const balance = await tokenContract.methods.balanceOf(userAddress).call()
      return balance
    } catch (e) {
      return '0'
    }
  }
}

export const PENDING_TX_FETCH_INTERVAL = 5000 // 5s
export const TRIGGER_DATE = 20210304

import React, { Suspense, lazy } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { SHOW_CLAIM } from 'helpers/config'

const Home = lazy(() => import('views/Home'))
const Staking = lazy(() => import('views/Staking'))
const XdefiCredits = lazy(() => import('views/XdefiCredits'))

const PublicRoutes = () => {
  return (
    <>
      <Suspense fallback={<></>}>
        <Switch>
          <Route exact path="/" component={SHOW_CLAIM ? Home : Staking} />
          {!SHOW_CLAIM ? <Route exact path="/stake" component={Staking} /> : ''}
          {!SHOW_CLAIM ? (
            <Route exact path="/xdefi-credits" component={XdefiCredits} />
          ) : (
            ''
          )}

          <Redirect to={'/'} />
        </Switch>
      </Suspense>
    </>
  )
}

export default PublicRoutes

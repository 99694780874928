import { combineReducers } from 'redux'
import userReducer from 'store/user/reducers'
import providerReducer from 'store/provider/reducers'
import transactionReducer from 'store/transaction/reducers'

export default combineReducers({
  user: userReducer,
  provider: providerReducer,
  transaction: transactionReducer,
})

import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { store } from 'store/index.js'
import AccountProvider from 'contexts/AccountProvider'
import PublicRoutes from 'PublicRoutes'

const queryClient = new QueryClient()

function App() {
  return (
    <ReduxProvider store={store}>
      <AccountProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <PublicRoutes />
          </Router>
        </QueryClientProvider>
      </AccountProvider>
    </ReduxProvider>
  )
}

export default App

import { useEffect } from 'react'

export function useInactiveListener() {
  useEffect(() => {
    const { ethereum } = window
    if (ethereum && ethereum.on) {
      const handleChainChanged = (chainId) => {
        console.log('Chain changed to ' + parseInt(chainId))
      }

      ethereum.on('chainChanged', handleChainChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
        }
      }
    }
    return undefined
  }, [])
}

import { stringToBoolean } from 'helpers/util'

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT

export const CLAIM_CONTRACT_ADDRESS =
  process?.env?.REACT_APP_CLAIM_CONTRACT_ADDRESS
export const XDEFI_TOKEN_ADDRESS = process?.env?.REACT_APP_XDEFI_TOKEN_ADDRESS
export const XDEFI_DISTRIBUTION_ADDRESS =
  process?.env?.REACT_APP_XDEFI_DISTRIBUTION_ADDRESS
export const XDEFI_DISTRIBUTION_HELPER_ADDRESS =
  process?.env?.REACT_APP_XDEFI_DISTRIBUTION_HELPER_ADDRESS
export const BLOCKNATIVE_KEY = 'b15165d0-e670-474c-8479-6888e86758c3'
export const DEFAULT_NETWORK_ID =
  Number(process?.env?.REACT_APP_DEFAULT_NETWORK_ID) || 3
export const SHOW_CLAIM =
  stringToBoolean(process?.env?.REACT_APP_SHOW_CLAIM) || false
export const SUPPORTED_CHAIN_IDS = [DEFAULT_NETWORK_ID]
export const XDEFI_TOKEN = {
  name: 'XDEFI',
  address: XDEFI_TOKEN_ADDRESS,
  symbol: 'XDEFI',
  decimals: 18,
  chainId: DEFAULT_NETWORK_ID,
  logoURI: 'assets/images/xdefi.png',
}

// need to be updated after contract set
export const MAINNET_LOCK_PERIODS = [
  {
    period: 1296000,
    lockPeriod: '15 days',
    lockMultiplier: 120, // 15 days with 1.2x
  },
  {
    period: 2592000,
    lockPeriod: '30 days',
    lockMultiplier: 140, // 30 days with 1.4x
    isDefault: true,
  },
  {
    period: 3888000,
    lockPeriod: '45 days',
    lockMultiplier: 160, // 45 day with 1.6x
  },
  {
    period: 5184000,
    lockPeriod: '60 days',
    lockMultiplier: 180, // 60 day with 1.8x
  },
  {
    period: 6480000,
    lockPeriod: '75 days',
    lockMultiplier: 200, // 75 days with 2x
  },
]

export const TESTNET_LOCK_PERIODS = [
  {
    period: 1,
    lockPeriod: 'None',
    lockMultiplier: 100, // 1 day with 1.2x
  },
  {
    period: 86400,
    lockPeriod: '1 day',
    isDefault: true,
    lockMultiplier: 120, // 1 day with 1.2x
  },
]

export const LOCK_PERIODS =
  DEFAULT_NETWORK_ID === 1 ? MAINNET_LOCK_PERIODS : TESTNET_LOCK_PERIODS

export const DEFAULT_LOCK_PERIOD =
  LOCK_PERIODS.find(({ isDefault }) => !!isDefault) || LOCK_PERIODS?.[0]

import { SAVE_WALLET, SAVE_BALANCE } from 'store/user/constants'

export function saveWallet(payload) {
  return {
    type: SAVE_WALLET,
    payload,
  }
}

export function saveBalance(payload) {
  return {
    type: SAVE_BALANCE,
    payload,
  }
}

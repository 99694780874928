import rootReducer from 'store/rootReducer'
import { createStore, applyMiddleware } from 'redux'
import { save, load } from 'redux-localstorage-simple'
import { composeWithDevTools } from 'redux-devtools-extension'
import * as Sentry from '@sentry/react'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
})

const PERSISTED_KEYS = ['user', 'provider', 'transaction']

const middlewares = [save({ states: PERSISTED_KEYS })]
const middlewareEnhancer = applyMiddleware(...middlewares)

const enhancers = [middlewareEnhancer, sentryReduxEnhancer]
const composedEnhancers = composeWithDevTools(...enhancers)

export const store = createStore(
  rootReducer,
  load({ states: PERSISTED_KEYS, disableWarnings: true }),
  composedEnhancers
)
